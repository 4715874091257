import styled from 'styled-components';
import { Tag } from 'antd';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
}

export const PageContainer = styled.main<CommonProps>`
  width: 100%;
  height: auto;
  background-color: ${theme.colors.white};
  overflow: hidden;
`;

export const ContentWrapper = styled.div<CommonProps>`
  max-width: ${theme.size.maxWidth};
  padding: ${({ $isMobile }) => ($isMobile ? '0px' : '60px')};
  margin: 0 auto;
`;

export const StyledTag = styled(Tag)`
  &&& {
    padding: 2px 8px;
    border-radius: 25px;
    margin: 0;
    font-size: 13px;
    cursor: inherit;
    font-weight: 400;
    pointer-events: none;
  }
`;
