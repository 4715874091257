import styled from 'styled-components';
import { motion } from 'framer-motion';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { timingFunctions } from 'polished';
import { Direction } from '@smakss/react-scroll-direction';

const ReactPlayer = dynamic(() => import('react-player'), {
  ssr: false,
});

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $playing?: boolean;
  $isInView?: boolean;
  $videoVisible?: boolean;
  $scrollDir?: Direction;
}

export const Wrapper = styled.div<CommonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: ${(props) =>
    props.$videoVisible ? theme.colors.black : 'transparent'};
  transition: background-color 0.5s ease-in
    ${(props) => (props.$videoVisible ? '0s' : '0.5s')};
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: ${theme.size.maxWidth};
`;

export const VideoWrapper = styled.div<CommonProps>`
  position: relative;
  padding-top: 56.25%;
  opacity: ${(props) => (props.$videoVisible ? 1 : 0)};
  transition: opacity 0.5s ease-in
    ${(props) => (props.$videoVisible ? '0s' : '0.5s')};
`;

export const VideoPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Thumbnail = styled(motion(Image))<CommonProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(
      -50%,
      ${(props) =>
        props.$scrollDir === 'up' || props.$scrollDir === 'still'
          ? props.$isInView
            ? '-50%'
            : 'calc(-50% + 100px)'
          : props.$isInView
            ? '-50%'
            : 'calc(-50% - 100px)'}
    )
    rotateX(${(props) => (props.$isInView ? '0deg' : '5deg')})
    rotateY(${(props) => (props.$isInView ? '0deg' : '-5deg')})
    rotateZ(${(props) => (props.$isInView ? '0deg' : '5deg')});
  width: ${(props) => (props.$isMobile ? 'calc(100% - 60px)' : '80%')};
  height: auto;
  opacity: ${(props) => (props.$videoVisible ? 0 : 1)};
  pointer-events: ${(props) => (props.$videoVisible ? 'none' : 'auto')};
  mask-image: url('/images/polygon-mask.svg');
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  z-index: 1;

  transition:
    opacity 1s ${(props) => (props.$videoVisible ? '0.5s' : '0s')} ease-out,
    transform 1.5s ${(props) => (!props.$isInView ? '1s' : '0s')}
      ${timingFunctions('easeOutQuint')};
`;

interface PlayButtonProps extends CommonProps {
  $visible: boolean;
}

export const PlayButton = styled.img<PlayButtonProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: ${(props) => (props.$isMobile ? '50px' : '80px')};
  cursor: pointer;
  z-index: 2;
  opacity: ${(props) => (props.$isInView && props.$visible ? 1 : 0)};
  transition:
    opacity 0.5s ${(props) => (props.$isInView ? '1s' : '0s')} ease-out,
    transform 0.2s ease-out;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &:active {
    transform: translate(-50%, -50%) scale(1.2);
  }
`;
