import styled from 'styled-components';
import { Space, Button, Typography, Row, Card, Slider } from 'antd';
import Image from 'next/image';
import { timingFunctions } from 'polished';

const { Title, Text, Paragraph } = Typography;

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
  $color?: string;
}

export const PerformanceBackground = styled.div<CommonProps>`
  &&& {
    position: relative;
    /* background: #001b1d; */
    width: 100%;
    height: ${(props) => (props.$isMobile ? 'auto' : '100vh')};
    display: flex;
    align-items: center;
  }
`;

export const PerformanceBackgroundSupergraphic = styled(Image)<CommonProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
  pointer-events: none;
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transition: opacity 1s 0.5s ease-out;
`;

export const PerformanceContainer = styled(Row)<CommonProps>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '120px 30px' : '100px 60px')};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;
  }
`;

export const PerformanceContentWrapper = styled(Space)<CommonProps>`
  &&& {
    position: ${(props) => (props.$isMobile ? 'relative' : 'absolute')};
    top: ${(props) => (props.$isMobile ? '0' : '15%')};
    z-index: 2;
  }
`;

export const PerformanceCardTitle = styled(Title)`
  &&& {
    color: ${theme.colors.white};
    margin-bottom: 0;
  }
`;

export const PerformanceCardSliderSpace = styled(Space)<CommonProps>`
  &&& {
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition-property: opacity, transform;
    transition-delay: 0.5s;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
  }
`;

export const PerformanceCardSliderText = styled(Text)`
  &&& {
    color: ${theme.colors.white};
    font-weight: 600;
  }
`;

export const PerformanceCardSliderTextValue = styled(Text)`
  &&& {
    color: ${theme.colors.teal};
    font-weight: 300;
    font-size: 24px;
  }
`;

export const PerformanceCardSliderTooltipText = styled(Text)`
  &&& {
    color: ${theme.colors.black};
    font-weight: 600;
  }
`;

export const PerformanceCardSlider = styled(Slider)<CommonProps>`
  &&& {
    margin-left: 0;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    width: ${(props) => (props.$isInView ? '100%' : '0')};

    transition-property: opacity, width;
    transition-delay: 0.5s;
    transition-duration: 1.5s;
    transition-timing-function: ${timingFunctions('easeInOutQuint')};

    .ant-slider-track {
      background: radial-gradient(
        circle,
        rgba(84, 151, 144, 1) 0%,
        rgba(37, 100, 94, 1) 46%,
        rgba(84, 151, 144, 1) 69%,
        rgba(37, 100, 94, 1) 100%
      );
    }

    .ant-slider-rail {
      background-color: #072f32;
    }

    .ant-slider-handle {
      &::after {
        box-shadow: none;
        background-color: ${theme.colors.white};
      }
    }
  }
`;

export const PerformanceCardDescription = styled(Paragraph)<CommonProps>`
  &&& {
    margin: 0;
    width: 100%;
    color: ${theme.colors.darkGrey};
    max-width: 400px;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition-property: opacity, transform;
    transition-delay: 1s;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
  }
`;

export const PerformanceCardWarning = styled(Paragraph)<CommonProps>`
  &&& {
    margin: 0;
    width: 100%;
    color: ${theme.colors.darkGrey};
    max-width: 400px;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition-property: opacity, transform;
    transition-delay: 1s;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
  }
`;

export const PerformanceCardGraphWrapper = styled.div<CommonProps>`
  &&& {
    position: relative;
    height: ${(props) => (props.$isMobile ? '200px' : '50vw')};
    max-height: 700px;
    left: 0;
    right: calc(50vw - 50%);
    width: calc(100% + calc(50vw - 50% + 5px));
    opacity: ${(props) => (props.$isInView ? 1 : 0)};

    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
  }
`;

export const PerformanceCardGraphXAxis = styled.div<CommonProps>`
  &&& {
    position: absolute;
    left: -25vw;
    bottom: ${(props) => (props.$isMobile ? '72px' : '56px')};
    height: 1px;
    background-color: #5f8c9a;
    width: ${(props) => (props.$isInView ? '122vw' : 0)};
  }
`;

export const PerformanceCardGraphStartDot = styled.div<CommonProps>`
  &&& {
    position: absolute;
    left: ${(props) => (props.$isMobile ? '19px' : '-10px')};
    bottom: ${(props) => (props.$isMobile ? '62px' : '42px')};
    width: ${(props) => (props.$isMobile ? '20px' : '30px')};
    height: ${(props) => (props.$isMobile ? '20px' : '30px')};
    border-radius: 100%;
    background-color: ${theme.colors.white};
    z-index: 99;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: ${(props) => (props.$isMobile ? '70px' : '120px')};
      height: ${(props) => (props.$isMobile ? '70px' : '120px')};
      border-radius: 100%;
      background-color: ${theme.colors.white};
      opacity: 0.05;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      width: 1px;
      height: ${(props) => (props.$isMobile ? '30px' : '50px')};
      background-color: ${theme.colors.white};
      opacity: 0.8;
      height: ${(props) => (props.$isInView ? '175%' : 0)};

      transition-property: height;
      transition-delay: 1s;
      transition-duration: 1s;
      transition-timing-function: ${timingFunctions('easeOutQuad')};
    }
  }
`;

export const PerformanceCardGraphStartValue = styled(Text)<CommonProps>`
  &&& {
    position: absolute;
    left: 0;
    bottom: ${(props) => (props.$isMobile ? '110px' : '130px')};
    ${(props) => !props.$isMobile && 'transform: translateX(calc(-50% + 5px))'};
    color: ${theme.colors.white};
    font-weight: 500;
    font-size: ${(props) => (props.$isMobile ? '22px' : '26px')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};

    transition-property: opacity;
    transition-delay: 1.5s;
    transition-duration: 2s;
    transition-timing-function: ${timingFunctions('easeInOutQuad')};
  }
`;

export const PerformanceCard = styled(Card)<CommonProps>`
  &&& {
    border: none;
    background-color: transparent;
    border-radius: 0;

    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-head-title {
      padding: 0;
    }
  }
`;

export const LineChartTooltipWrapper = styled.div`
  background-color: ${theme.colors.white};
  padding: 10px 20px 15px;
  border-radius: 15px;
`;

export const LineChartTooltipTitle = styled(Title)`
  &&& {
    margin-bottom: 8px;
  }
`;

export const LineChartTooltipData = styled.div``;

export const LineChartTooltipDataItem = styled.div<CommonProps>`
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LineChartTooltipDataItemAsset = styled(Text)<CommonProps>`
  &&& {
    font-size: 14px;
    margin-right: 10px;
    text-align: left;

    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: ${(props) => props.$color};
      border-radius: 50%;
      margin-right: 10px;
    }
  }
`;

export const LineChartTooltipDataItemValue = styled(Text)`
  &&& {
    font-size: 14px;
    font-weight: bold;
  }
`;
