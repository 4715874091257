import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Typography } from 'antd';

export const StyledTitle = styled(Typography.Title)`
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledWord = styled.span`
  display: inline-block;
  margin-right: 0.25em;
`;

export const StyledCharacter = styled(motion.span)`
  display: inline-block;
`;

export const StyledLine = styled(motion.div)`
  display: block;
  width: 100%;
`;
