import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { Col, Grid, Tooltip as AntTooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useInView } from 'framer-motion';
import {
  LineChart,
  Line,
  Customized,
  Tooltip,
  XAxis,
  ReferenceDot,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import debounce from 'lodash/debounce';
import posthog from 'posthog-js';

import { VC_AVG_RETURNS, PORTFOLIO_COMPARISONS } from 'config';

import AnimatedTitle from 'components/AnimatedTitle';
import MemoizedCustomGradient from './MemoizedCustomGradient';

import { useLogoState } from 'context/LogoContext';

import { currencyFormatter, abbreviateNumber } from 'utils/strings';

import theme from 'styles/theme';
import {
  PerformanceBackground,
  PerformanceBackgroundSupergraphic,
  PerformanceContainer,
  PerformanceContentWrapper,
  PerformanceCard,
  PerformanceCardGraphWrapper,
  PerformanceCardSliderText,
  PerformanceCardSliderTextValue,
  PerformanceCardSliderSpace,
  PerformanceCardSlider,
  PerformanceCardDescription,
  PerformanceCardWarning,
  LineChartTooltipWrapper,
  PerformanceCardGraphXAxis,
  PerformanceCardGraphStartDot,
  PerformanceCardGraphStartValue,
  LineChartTooltipTitle,
  LineChartTooltipData,
  LineChartTooltipDataItem,
  LineChartTooltipDataItemAsset,
  LineChartTooltipDataItemValue,
} from './styles';

export interface HomePagePerformanceProps {
  copy: any;
}

export default function HomePagePerformance({
  copy,
}: HomePagePerformanceProps) {
  const screens = Grid.useBreakpoint();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5 });

  const [quarterlyInvestment, setQuarterlyInvestment] = useState(2500);

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { setIsDarkBackground } = useLogoState();
  const isInViewLogo = useInView(containerRef, { amount: 0.5 });

  const [seedValues, setSeedValues] = useState<number[]>([]);

  useEffect(() => {
    if (isInViewLogo) setIsDarkBackground(true);
  }, [isInViewLogo, setIsDarkBackground]);

  useEffect(() => {
    let mouseMoved = false;

    const cleanupListeners = (): void => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };

    const handleMouseMove = (): void => {
      if (mouseMoved) return;
      mouseMoved = true;

      cleanupListeners();

      tooltipTimeoutRef.current = setTimeout(() => {
        setShowTooltip(true);
      }, 500);
    };

    if (isInView) {
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      setShowTooltip(false);
    }

    return cleanupListeners;
  }, [isInView, setShowTooltip]);

  const generateFutureReturns = (quarterlyInvestment) => {
    // If we don't have seed values yet, generate them for the maximum possible period
    if (seedValues.length === 0) {
      const maxPeriod = 20; // Maximum investment period
      const newSeedValues = Array.from({ length: maxPeriod }, () =>
        Math.random()
      );
      setSeedValues(newSeedValues);
    }

    const startYear = 0;
    const projectionArray = [];
    let futureValue = quarterlyInvestment;
    const investmentPeriod = 20;
    const compareWith = ['sp500', 'savingsAccount'];

    // Initialize an object to track the future values for each comparison
    const futureComparisonValues = {};
    compareWith.forEach((item) => {
      futureComparisonValues[item] = quarterlyInvestment;
    });

    let totalInvestedCapital = 0;

    const addVisualWaves = (baseValue, year) => {
      // Use consistent seed value for each year
      const seed = seedValues[year - 1] || Math.random();
      const waveEffect =
        Math.sin(year / (2 + seed)) * 0.06 +
        Math.cos(year / (3 + seed)) * 0.04 +
        Math.sin(year / (5 + seed)) * 0.02;
      return baseValue * (1 + waveEffect);
    };

    for (let year = 1; year <= investmentPeriod + startYear; year++) {
      // Core return calculation (with no volatility to ensure final outcome is unchanged)
      const adjustedAnnualReturn = VC_AVG_RETURNS;

      // Calculate the future value for the current year
      futureValue =
        futureValue * (1 + adjustedAnnualReturn) +
        (year > 1 ? quarterlyInvestment * 4 || 1000 : 0);

      // Add this year's contribution to total invested capital
      if (year > 1) {
        totalInvestedCapital += quarterlyInvestment * 4 || 1000; // Adding yearly investment to total capital
      } else {
        totalInvestedCapital = quarterlyInvestment; // Initial investment
      }

      // Calculate future value for each comparison
      compareWith.forEach((item) => {
        const adjustedAnnualComparisonReturn =
          PORTFOLIO_COMPARISONS[item].value;

        // Update the future value for the current comparison
        futureComparisonValues[item] =
          futureComparisonValues[item] * (1 + adjustedAnnualComparisonReturn) +
          (year > 1 ? quarterlyInvestment * 4 || 1000 : 0);
      });

      // Only start recording projections after 5 years
      if (year > startYear) {
        let projectionObject = {
          name: `${new Date().getFullYear() + year - 1}`,
          'Venture Capital': addVisualWaves(futureValue, year), // Unique wave
          'Invested Capital': totalInvestedCapital, // Include total invested capital here
        };

        compareWith.forEach((item) => {
          projectionObject[PORTFOLIO_COMPARISONS[item].name] = addVisualWaves(
            futureComparisonValues[item],
            year
          ); // Unique wave per asset
        });

        projectionArray.push(projectionObject);
      }
    }

    return projectionArray;
  };

  const isMobile = screens.md === false;

  const chartData = useMemo(() => {
    return generateFutureReturns(quarterlyInvestment);
  }, [quarterlyInvestment]);

  const secondLastPointIndex = chartData.length - 2;
  const secondLastPoint = chartData[secondLastPointIndex];

  const CustomLineChartTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <LineChartTooltipWrapper>
          <LineChartTooltipTitle level={4}>
            {payload[0].payload.name}
          </LineChartTooltipTitle>
          <LineChartTooltipData>
            {payload.map((item) => (
              <LineChartTooltipDataItem key={item.name}>
                <LineChartTooltipDataItemAsset $color={item.color}>
                  {item.name}:
                </LineChartTooltipDataItemAsset>
                <LineChartTooltipDataItemValue>
                  {`€${abbreviateNumber(item.value)}`}
                </LineChartTooltipDataItemValue>
              </LineChartTooltipDataItem>
            ))}
          </LineChartTooltipData>
        </LineChartTooltipWrapper>
      );
    }

    return null;
  };

  const onSliderChange = (value: number) => {
    setQuarterlyInvestment(value);
    // Only capture the final value when the user stops moving the slider
    const debouncedCapture = debounce(() => {
      posthog.capture('homepage_performance_slider_change', {
        value,
      });
    }, 500);
    debouncedCapture();
  };

  const formattedValue = `€${abbreviateNumber(secondLastPoint['Venture Capital'])}`;

  const renderLine = (key: string, color: string) => (
    <Line
      key={`${key}-${isInView}`}
      legendType="plainline"
      type="monotone"
      dataKey={key}
      stroke={color}
      strokeWidth={key === 'Venture Capital' ? 3 : 1}
      isAnimationActive={isInView}
      animationDuration={1500}
      animationBegin={0}
      dot={{ strokeWidth: 0, r: 0 }}
      activeDot={{
        stroke: theme.colors.white,
        strokeWidth: 6,
        r: 1,
      }}
      hide={!isInView}
    />
  );

  const renderCustomGradient = useCallback(
    (props) => {
      return (
        <MemoizedCustomGradient
          {...props}
          isMobile={isMobile}
          isInView={isInView}
        />
      );
    },
    [isMobile, isInView]
  );

  return (
    <PerformanceBackground $isMobile={isMobile} $isInView={isInView}>
      <PerformanceBackgroundSupergraphic
        $isInView={isInView}
        src="/images/supergraphic.svg"
        alt=""
        width={240}
        height={204}
      />
      <PerformanceContainer
        ref={containerRef}
        $isInView={isInView}
        $isMobile={isMobile}
      >
        <Col sm={24} md={12} style={{ position: 'initial' }}>
          <PerformanceContentWrapper
            direction="vertical"
            size={isMobile ? 15 : 30}
            $isMobile={isMobile}
          >
            <AnimatedTitle
              text={copy.title}
              level={screens.md ? 1 : 3}
              isInView={isInView}
              once={false}
              style={{
                color: theme.colors.white,
                marginBottom: 0,
              }}
            />
            <div>
              <PerformanceCardSliderSpace
                size={10}
                align="end"
                $isInView={isInView}
              >
                <PerformanceCardSliderText>
                  {copy.slider}:
                </PerformanceCardSliderText>
                <PerformanceCardSliderTextValue>
                  {currencyFormatter(quarterlyInvestment)}
                </PerformanceCardSliderTextValue>
              </PerformanceCardSliderSpace>
              <PerformanceCardSlider
                $isInView={isInView}
                min={250}
                max={10000}
                step={250}
                value={quarterlyInvestment}
                onChange={onSliderChange}
                tooltip={{
                  overlayStyle: {
                    display: 'none',
                  },
                }}
              />
            </div>
            <PerformanceCardDescription $isInView={isInView}>
              {copy.description}{' '}
              <AntTooltip
                title={copy.warning}
                overlayInnerStyle={{
                  backgroundColor: theme.colors.white,
                  color: '#6B7279',
                  padding: '15px 20px',
                  width: 300,
                }}
              >
                <ExclamationCircleOutlined
                  style={{
                    color: `${theme.colors.warning}99`,
                    transform: 'translateY(2px)',
                  }}
                />
              </AntTooltip>
            </PerformanceCardDescription>
          </PerformanceContentWrapper>
        </Col>
        <Col sm={24} md={24}>
          <PerformanceCard
            $isInView={isInView}
            styles={{
              body: { padding: screens.md ? 0 : 24 },
            }}
          >
            <PerformanceCardGraphXAxis
              $isMobile={isMobile}
              $isInView={isInView}
            />
            <PerformanceCardGraphStartDot
              $isMobile={isMobile}
              $isInView={isInView}
            />
            <PerformanceCardGraphStartValue
              $isMobile={isMobile}
              $isInView={isInView}
            >
              €{abbreviateNumber(quarterlyInvestment)}
            </PerformanceCardGraphStartValue>
            <PerformanceCardGraphWrapper
              $isMobile={screens.md === false}
              $isInView={isInView}
            >
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={chartData}>
                  {!isMobile && (
                    <Tooltip
                      content={CustomLineChartTooltip}
                      cursor={false}
                      wrapperStyle={{
                        opacity: isInView && showTooltip ? 1 : 0,
                        transition:
                          'opacity 0.3s ease-in-out, transform 0.5s cubic-bezier(0.25, 1, 0.5, 1)',
                      }}
                    />
                  )}
                  {renderLine('Venture Capital', theme.colors.teal)}
                  {renderLine('S&P 500', theme.colors.lavender)}
                  {renderLine('Savings Account', theme.colors.lavenderGrey)}
                  {renderLine('Invested Capital', theme.colors.coolGrey)}
                  <ReferenceDot
                    x={secondLastPoint.name}
                    y={secondLastPoint['Venture Capital']}
                    r={6}
                    stroke="none"
                    fill="#fff"
                    label={{
                      position: {
                        x: 0,
                        y: -10,
                      },
                      value: formattedValue,
                      fill: theme.colors.white,
                      fontSize: isMobile ? '32px' : '48px',
                      fontWeight: 500,
                    }}
                  />
                  <Customized component={renderCustomGradient} />
                  <XAxis
                    dataKey="name"
                    height={30}
                    axisLine={false}
                    tick={{
                      fontSize: isMobile ? 9 : 12,
                    }}
                    tickMargin={isMobile ? 10 : 20}
                    interval="equidistantPreserveStart"
                  />
                  <Legend
                    verticalAlign="bottom"
                    height={0}
                    wrapperStyle={{
                      bottom: isMobile ? -10 : -30,
                      fontSize: isMobile ? 9 : 14,
                    }}
                    iconType="circle"
                    iconSize={0.1}
                  />
                </LineChart>
              </ResponsiveContainer>
            </PerformanceCardGraphWrapper>
          </PerformanceCard>
        </Col>
      </PerformanceContainer>
    </PerformanceBackground>
  );
}
