import { useEffect, useRef, CSSProperties } from 'react';
import { useAnimation } from 'framer-motion';
import { Grid } from 'antd';

import { StyledTitle, StyledLine, StyledWord } from './styles';

export interface AnimatedTitleProps {
  text: string;
  isInView: boolean;
  level?: 1 | 2 | 3 | 4 | 5;
  style?: CSSProperties;
  once?: boolean;
  delay?: number;
  showInline?: boolean;
}

export default function AnimatedTitle({
  text,
  isInView,
  level = 1,
  style = {},
  once = true,
  delay = 0.15,
  showInline = false,
}: AnimatedTitleProps) {
  const screens = Grid.useBreakpoint();
  const ctrls = useAnimation();
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;

    if (isInView) {
      setTimeout(() => {
        if (mountedRef.current) {
          ctrls.start('visible');
        }
      }, 10);
    }

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      ctrls.start('visible');
    } else {
      if (!once) ctrls.start('hidden');
    }
  }, [ctrls, once, isInView, screens]);

  const lineAnimation = {
    hidden: { opacity: 0, y: '20px' },
    visible: (i: number) => ({
      opacity: 1,
      y: '0px',
      transition: {
        opacity: {
          delay: i * delay,
          duration: 2,
          ease: [0.25, 1, 0.5, 1],
        },
        y: {
          delay: i * delay,
          duration: 1,
          ease: [0.25, 1, 0.5, 1],
        },
      },
    }),
  };

  // Split text into lines and trim each line
  const lines = showInline
    ? [text]
    : text.split('\n').map((line) => line.trim());

  return (
    <StyledTitle aria-label={text} role="heading" level={level} style={style}>
      {lines.map((line, lineIndex) => (
        <StyledLine
          key={`line_${lineIndex}`}
          aria-hidden="true"
          initial="hidden"
          animate={ctrls}
          custom={lineIndex}
          variants={lineAnimation}
        >
          {line
            .split(' ')
            .filter(Boolean)
            .map((word, wordIndex) => (
              <StyledWord key={`word_${lineIndex}_${wordIndex}`}>
                {word}
              </StyledWord>
            ))}
        </StyledLine>
      ))}
    </StyledTitle>
  );
}
