import { memo } from 'react';
import theme from 'styles/theme';
import { motion } from 'framer-motion';

interface CustomGradientProps {
  formattedGraphicalItems: any[];
  height: number;
  isMobile: boolean;
  isInView: boolean;
}

const MemoizedCustomGradient = memo(
  ({
    formattedGraphicalItems,
    height,
    isMobile,
    isInView,
  }: CustomGradientProps) => {
    const gradientId = 'customFillGradient';
    const bottomOffset = isMobile ? 49 : 56;
    const points = formattedGraphicalItems[0]?.props.points;

    if (!points) {
      return null;
    }

    return (
      <g>
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor={theme.colors.white} stopOpacity={0} />
            <stop offset="70%" stopColor={theme.colors.teal} stopOpacity={0} />
            <stop
              offset="100%"
              stopColor={theme.colors.chartreuse}
              stopOpacity={0.2}
            />
          </linearGradient>
        </defs>
        <motion.path
          d={`M ${points[0].x},${height - bottomOffset} 
          L ${points.map((p) => `${p.x},${p.y}`).join(' ')} 
          L ${points[points.length - 1].x},${height - bottomOffset} Z`}
          fill={`url(#${gradientId})`}
          stroke="none"
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{
            opacity: {
              duration: 2,
              delay: isInView ? 2 : 0,
              ease: [0.45, 0, 0.55, 1],
            },
          }}
        />
      </g>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.isInView === nextProps.isInView;
  }
);

MemoizedCustomGradient.displayName = 'MemoizedCustomGradient';

export default MemoizedCustomGradient;
