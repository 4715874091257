import { useState, useEffect, useRef, useCallback } from 'react';
import Link from 'next/link';
import { Row, Col, Grid } from 'antd';
import { useInView } from 'framer-motion';

import WaitlistModal from 'components/WaitlistModal';
import AnimatedTitle from 'components/AnimatedTitle';

import { useLogoState } from 'context/LogoContext';

import {
  SADInvestingBackground,
  SADInvestingContainer,
  SADInvestingSubtitle,
  SADInvestingCard,
  SADInvestingCardHeader,
  SADInvestingCardHeaderIllustration,
  SADInvestingCardTitle,
  SADInvestingCardDescription,
} from './styles';

import headerIconStepOne from 'assets/images/home/SADInvesting/quarterly.png';
import headerIconStepTwo from 'assets/images/home/SADInvesting/curated.png';
import headerIconStepThree from 'assets/images/home/SADInvesting/diversification.png';

export interface HomePageSADInvestingProps {
  copy: any;
}

export default function HomePageSADInvesting({
  copy,
}: HomePageSADInvestingProps) {
  const screens = Grid.useBreakpoint();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, {
    amount: screens.sm === false ? 0.1 : 0.5,
  });

  const { setIsDarkBackground } = useLogoState();

  useEffect(() => {
    if (isInView) {
      setIsDarkBackground(false);
    }
  }, [isInView, setIsDarkBackground]);

  const steps = [
    {
      icon: headerIconStepOne,
      title: copy.value1.title,
      description: copy.value1.description,
    },
    {
      icon: headerIconStepTwo,
      title: copy.value2.title,
      description: copy.value2.description,
    },
    {
      icon: headerIconStepThree,
      title: copy.value3.title,
      description: copy.value3.description,
    },
  ];

  const isMobile = screens.md === false;

  return (
    <SADInvestingBackground $isMobile={isMobile}>
      <SADInvestingContainer
        align="middle"
        ref={containerRef}
        $isInView={isInView}
        $isMobile={isMobile}
      >
        <Col span={24}>
          <AnimatedTitle
            text={copy.title}
            level={screens.md ? 1 : 3}
            isInView={isInView}
            once={false}
            style={{
              textAlign: 'center',
              margin: '0 auto 20px',
            }}
          />
          <SADInvestingSubtitle $isInView={isInView} $isMobile={isMobile}>
            {copy.subtitle}
          </SADInvestingSubtitle>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            {steps.map((step, index) => (
              <Col key={step.title} sm={24} md={8} lg={8}>
                <SADInvestingCard
                  $isInView={isInView}
                  $isMobile={isMobile}
                  styles={{
                    header: {
                      padding: 0,
                    },
                  }}
                  className={`SADInvestingCard_${index}`}
                  title={
                    <SADInvestingCardHeader $isMobile={isMobile}>
                      <SADInvestingCardHeaderIllustration
                        $isMobile={isMobile}
                        src={step.icon}
                        alt=""
                      />
                    </SADInvestingCardHeader>
                  }
                >
                  <SADInvestingCardTitle level={3}>
                    {step.title}
                  </SADInvestingCardTitle>
                  <SADInvestingCardDescription $isMobile={isMobile}>
                    {step.description}
                  </SADInvestingCardDescription>
                </SADInvestingCard>
              </Col>
            ))}
          </Row>
        </Col>
      </SADInvestingContainer>
    </SADInvestingBackground>
  );
}
