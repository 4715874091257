import styled, { css } from 'styled-components';
import { Typography, Row } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
  $isActive?: boolean;
  $hoverActive?: boolean;
}

const pulseAnimation = css`
  @keyframes pulse {
    0%,
    100% {
      transform: scale(1) translate(var(--translateX), var(--translateY));
    }
    10% {
      transform: scale(1.025) translate(var(--translateX), var(--translateY));
    }
    20% {
      transform: scale(1) translate(var(--translateX), var(--translateY));
    }
  }
`;

export const RedefinedBackground = styled.div<CommonProps>`
  &&& {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RedefinedContainer = styled(Row)<CommonProps>`
  &&& {
    width: 100%;
    height: 100%;
    padding: ${(props) => (props.$isMobile ? '120px 30px' : '100px 60px')};
    max-width: 1800px;
    margin: 0 auto;
  }
`;

export const RedefinedBubbleContainer = styled.div<CommonProps>`
  &&& {
    position: relative;
    margin: ${(props) => (props.$isMobile ? '0 auto' : '0')};
    border: 1px solid ${theme.colors.lightGrey};
    border-radius: 50%;
    overflow: hidden;
    width: ${(props) => (props.$isMobile ? '320px' : '90%')};
    padding-top: ${(props) => (props.$isMobile ? '320px' : '90%')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'rotate(0deg)' : 'rotate(-45deg)'};

    transition-property: transform, opacity;
    transition-duration: 3s, 2s;
    transition-timing-function: ${timingFunctions('easeOutCirc')};
    transition-delay: 0s;
  }
`;

export const RedefinedBubbleOverflowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
`;

const commonStyles = css<CommonProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 55%;
  height: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  mix-blend-mode: multiply;
  opacity: ${(props) => (props.$isMobile ? 0.8 : 1)};
  border-radius: 50%;
  z-index: 1;
`;

const commonTransitionStyles = css<CommonProps>`
  --translateX: -50%;
  --translateY: -50%;
  transition-property: transform;
  transition-duration: 2s;
  transition-timing-function: ${timingFunctions('easeInOutCirc')};
  transition-delay: 0.7s;

  animation: ${(props) =>
    props.$isInView
      ? `pulse 5s ${timingFunctions('easeInOutSine')} infinite`
      : 'none'};

  ${pulseAnimation}

  &:hover {
    cursor: pointer;
  }
`;

export const RedefinedAccessContainer = styled.div<CommonProps>`
  &&& {
    ${commonStyles}
    ${commonTransitionStyles}

    background: linear-gradient(0deg, ${theme.colors.chartreuse} 0%, #fff 100%);
    --translateX: -50%;
    --translateY: -99%;
    transform: ${(props) =>
      props.$isInView ? 'translate(-50%, -99%)' : 'translate(-50%, -105%)'};

    animation-delay: 5s;
    transform-origin: left top;
  }
`;

export const RedefinedTrustContainer = styled.div<CommonProps>`
  &&& {
    ${commonStyles}
    ${commonTransitionStyles}

    background: linear-gradient(210deg, ${theme.colors.teal} 0%, #fff 100%);
    --translateX: -94%;
    --translateY: -25%;
    transform: ${(props) =>
      props.$isInView ? 'translate(-94%, -25%)' : 'translate(-104%, -10%)'};

    animation-delay: 5.5s;
    transform-origin: left center;
  }
`;

export const RedefinedSimplicityContainer = styled.div<CommonProps>`
  &&& {
    ${commonStyles}
    ${commonTransitionStyles}

    background: linear-gradient(120deg, ${theme.colors.lavender} 0%, #fff 100%);
    --translateX: -6%;
    --translateY: -25%;
    transform: ${(props) =>
      props.$isInView ? 'translate(-6%, -25%)' : 'translate(6%, -10%)'};

    animation-delay: 6s;
  }
`;

export const RedefinedBubbleTitle = styled(Typography.Title)<CommonProps>`
  &&& {
    margin-bottom: ${(props) => (props.$isMobile ? '0' : '12px')};
    color: ${theme.colors.black};
    text-align: center;
  }
`;

export const RedefinedBubbleDescription = styled(Typography.Text)<CommonProps>`
  &&& {
    text-align: center;
    display: ${(props) => (props.$isMobile ? 'none' : 'block')};
    max-width: ${(props) => (props.$isMobile ? 'none' : '200px')};
    font-size: 14px;
    transition: opacity 0.7s ease-out 0.3s;
  }
`;
