import styled, { keyframes, css } from 'styled-components';
import Image from 'next/image';
import { Typography, Row, Card, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
}

export const SADInvestingBackground = styled.div<CommonProps>`
  &&& {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SADInvestingContainer = styled(Row)<CommonProps>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '120px 30px 200px' : '80px 60px')};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;
    height: 100%;
    align-content: center;

    @media (min-width: ${theme.breakpoints.md}) and (max-height: 850px) {
      padding-top: 35px;
    }
  }
`;

export const SADInvestingSubtitle = styled(Typography.Paragraph)<CommonProps>`
  &&& {
    margin: 0 auto;
    max-width: 350px;
    color: ${theme.colors.bodyText};
    font-size: ${(props) => (props.$isMobile ? '16px' : '18px')};
    margin-bottom: ${(props) => (props.$isMobile ? '30px' : '40px')};
    text-align: center;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(20px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-delay: 0.3s;
    transition-timing-function: ${timingFunctions('easeOutQuart')};
  }
`;

export const SADInvestingOverflowWrap = styled.div`
  overflow: hidden;
`;

export const SADInvestingCardTitle = styled(Typography.Title)`
  &&& {
    color: ${theme.colors.black};
  }
`;

export const SADInvestingCardDescription = styled(
  Typography.Paragraph
)<CommonProps>`
  &&& {
    margin-bottom: 0px;
    width: ${(props) => (props.$isMobile ? '100%' : '100%')};
    color: ${theme.colors.textSecondary};
  }
`;

export const SADInvestingCard = styled(Card)<CommonProps>`
  &&& {
    border: none;
    border: 1px solid ${theme.colors.lightGrey};
    border-radius: 20px;
    overflow: hidden;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(120px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuart')};

    &.SADInvestingCard_0 {
      transition-delay: 0.3s;
    }

    &.SADInvestingCard_1 {
      transition-delay: 0.5s;
    }

    &.SADInvestingCard_2 {
      transition-delay: 0.7s;
    }

    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-head-title {
      padding: 0;
    }

    @media (min-width: ${theme.breakpoints.md}) and (max-height: 850px) {
      .ant-card-body {
        padding-top: 0;
      }
    }
  }
`;

export const SADInvestingCardHeader = styled.div<CommonProps>`
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.$isMobile ? '0' : '0px 40px 0px')};
`;

export const SADInvestingCardHeaderIllustration = styled(Image)<CommonProps>`
  &&& {
    width: 100%;
    height: auto;

    @media (min-width: ${theme.breakpoints.md}) and (max-height: 850px) {
      width: 80%;
    }
  }
`;

export const SADInvestingCardIcon = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${theme.colors.primarySofter};
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const SADInvestingCardCta = styled(Button)`
  &&& {
    padding: 0;

    span {
      text-decoration: underline;
    }
  }
`;

export const SADInvestingCta = styled(Button)<CommonProps>`
  &&& {
    margin: 70px 0 0px;
    min-width: 200px;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 2s;
  }
`;
