import styled from 'styled-components';
import { Row } from 'antd';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
}

export const EducationBackground = styled.div`
  &&& {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const EducationContainer = styled.div<CommonProps>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '50px 0' : '100px 0 0')};
    width: 100%;
    max-width: ${(props) => (props.$isMobile ? '100%' : '1700px')};

    @media (min-width: ${theme.breakpoints.md}) and (max-height: 800px) {
      padding-top: 35px;
    }
  }
`;

export const EducationCardWrapper = styled(Row)<CommonProps>`
  &&& {
    padding: ${(props) => (props.$isMobile ? '0 30px' : '0 60px 100px')};

    overflow-x: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
